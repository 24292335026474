import React, { FC, useEffect, useState, Fragment, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import Prices from "components/Prices";
import { useLocation } from "react-router-dom";
import axiosInstance from "api/AxiosInstance";
import { config } from "utils/Constance";
import ImageNotFound from "images/NoImageAvailable.png";
import CurrencyFormat from "components/CurrencyFormatter";
import TaxCodeComponent from "./TaxCodeComponent";
import AlertMessage from "./AlertMessage";
import { Dialog, Transition } from "@headlessui/react";
import Backdrop from "../../shared/Confirmdialogbox/Backdrop";
import DialogPanel from "../../shared/Confirmdialogbox/DialogPanel";
import InvoiveTo from "images/invoice-to.png";
import ShipTo from "images/ship-to.png";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import { getCartData } from "../../store/slices/cartSlice";
import { getCartListData } from "../../store/slices/cartDetailListSlice";
import ButtonCSCLed from "shared/Button/ButtonCSCLed";
import HeadingText1 from "components/HeadingText/HeadingText1";
import CartPageLoader from "components/CSCLedLoader/CartPageLoader";
import {
  CheckBadgeIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid";
import { isOutOfStock,isOutOfStockInventoryTotalDTO, ValidateQtyCount,ValidateTotalInventoryQtyCount } from "utils/configration";
import { getCartIndexListData } from "../../store/slices/cartIndexListSlice";
import { bool } from "yup";

export interface ReviewOrderProps {
  className?: string;
}
interface ConfirmShoppingCartDetail {
  ifCentralPurchase?: boolean | null;
  shippingAddressDetailId: number;
  purchaseOrderNumber: string;
  shippingCourier: string;
  selectedCustomerId: number;
  shippingPrefrence: string;
  discountValue?: number | null;
  freeShipping: string;
  notFreeShipping: string;
}

interface CartPurchaseOrderFileModel {
  purchaseOrderFileName: string;
  purchaseOrderFileURL: string;
}

interface ShoppingCartRequest {
  confirmformData: ConfirmShoppingCartDetail;
  purchaseOrderFileData: CartPurchaseOrderFileModel;
}

interface ValidateProductQty {
  isClearanceValidate: boolean;
  isOutOfStockValidate: boolean;
}

const ReviewOrder: FC<ReviewOrderProps> = ({ className = "" }) => {
  let price: any;
  price = 0.0;

  let totalCartPrice: any;
  totalCartPrice = 0.0;

  let Discount: any;
  Discount = 0.0;

  const [isOutOfStockOrNotForLog, setisOutOfStockOrNotForLog] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const defaultQuantityValidation: ValidateProductQty = {
    isClearanceValidate: false,
    isOutOfStockValidate: false,
  };

  const [validateProductQty, setValidateProductQty] =
      useState<ValidateProductQty>(defaultQuantityValidation);

  //let shippingCodeName: any;
  const navigate = useNavigate();
  const [customerInvoiceDetails, setcustomerInvoiceDetails] = useState("");
  const [shipToDetails, setshipToDetails] = useState("");
  const [shoppingCartDetails, setShoppingCartDetails] = useState<any>([]);
  const [shoppingCartSum, setshoppingCartSum] = useState(false);
  const [websiteDiscount, setWebsiteDiscount] = useState<boolean>();
  const [shippingPrefrence, setshippingPrefrence] = useState("");
  const [shippingCodeName, setshippingCodeName] = useState("");
  const [firstNameLastName, setfirstNameLastName] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isAlertMessageDisplay, setIsAlertMessageDisplay] = useState(false);
  // const [selectedItem, setSelectedItem] = useState<any>(null);
  // const [showConfirmation, setShowConfirmation] = useState(false);
  // const [isOpen, setIsOpen] = useState(false);
  // const [showModal, setShowModal] = useState(false);

  const [isClearanceModal, setClearanceModal] = useState(false);
  const [isNotClearanceModal, setNotClearanceModal] = useState(false);
  const [isPlaceOrderButtonDisplay, setPlaceOrderButtonDisplay] =
    useState(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [countClearanceQty, setCountClearanceQty] = useState<number>(0);

  const location = useLocation();
  const { formData } = location.state || {};
  console.log("Get data from Check-Out page formdata=>", formData);
  // Create a reference for the hidden span element
  const discountRef = useRef<any>(null);

  const [errors, setErrors] = useState({
    FormData,
  });

  useEffect(() => {
    fetchData();
    checkClearanceQty();
  }, []);

  const fetchData = async () => {
    try {
      // First API call to fetch shopping cart details
      if (formData) {
        const responseCustomerInvoice = await axiosInstance.get(
          `/Cart/GetCustomerInvoiceToDetails?CustomerId=${formData.selectedCustomerId}`
        );
        var customerdetails = responseCustomerInvoice.data;

        const fields = [
          customerdetails.customer1,
          customerdetails.billingStreet1,
          customerdetails.billingStreet2,
          `${customerdetails.billingCity ?? ""} ${
            customerdetails.billingProvince ?? ""
          } ${customerdetails.billingPostalCode ?? ""}`,
          customerdetails.billingCountry,
          `Tel. ${customerdetails.billingPhoneNumber ?? ""}`,
          customerdetails.billingEmail,
        ];
        const customerInvoiceToString = fields
          .map((field) => field ?? "")
          .join("\n");
        setcustomerInvoiceDetails(customerInvoiceToString);
        // console.log("====", customerInvoiceToString);
      }
       console.log("formData.shippingAddressDetails", formData.shippingAddressDetails);
      if (formData && formData.shippingAddressDetails !== 0) {
        const responseShipTo = await axiosInstance.get(
          `/Cart/GetShippingToDetailsById?ShipId=${formData.shippingAddressDetails}`
        );
        if (responseShipTo.data !== "") {
          var ShipTodetails = responseShipTo.data;

          const shipTofields = [
            ShipTodetails.companyName,
            ShipTodetails.firstName + " " + ShipTodetails.lastName,
            ShipTodetails.addressField1,
            ShipTodetails.addressField2 ?? "",
            `${ShipTodetails.city ?? ""} ${ShipTodetails.province ?? ""} ${
              ShipTodetails.postalCode ?? ""
            }`,
            ShipTodetails.country,
            `Tel. ${ShipTodetails.phoneNumber ?? ""}`,
            ShipTodetails.emailAddress,
          ];
          const ShipToString = shipTofields
            .filter((field) => field !== "")
            .join("\n");
          setshipToDetails(ShipToString);

          setshippingCodeName(ShipTodetails.province);
        }
      }

      const response = await axiosInstance.get(`/Cart`);
      setShoppingCartDetails(response.data);
      var cartItemList = response.data;
    
      // eslint-disable-next-line array-callback-return
      cartItemList.map((_item: any) => {
        const isOutOfStockOrNot = isOutOfStock(
          _item.qty,
          _item.invDTO.abQty,
          _item.invDTO.bcQty,
          _item.invDTO.onQTY
        );

        const isInventoryClearanceQTYCheck = isOutOfStockInventoryTotalDTO(
          _item.qty,
          _item.inventoryQTYDTO.totalAvailableForSale,
        );
        
        setisOutOfStockOrNotForLog(isOutOfStockOrNot);

        if (
          _item &&
          _item.invDTO &&
          _item.invDTO.clearance === true &&          
         // isOutOfStockOrNot === true
          isInventoryClearanceQTYCheck === true
        ) {
          setClearanceModal(true);
          setPlaceOrderButtonDisplay(true);
          setCountClearanceQty(ValidateTotalInventoryQtyCount(cartItemList,true));
        } else if (
          _item &&
          _item.invDTO &&
          _item.invDTO.clearance === false &&
          isOutOfStockOrNot === true
        ) {
          setNotClearanceModal(true);
          setPlaceOrderButtonDisplay(false);
        } else {
          setClearanceModal(false);
          setNotClearanceModal(false);
          setPlaceOrderButtonDisplay(false);
          setCountClearanceQty(0);
        }
      });

      const responseCartSum = await axiosInstance.get(
        `/Cart/GetShoppingCartTotalSum`
      );
      const cartSum = responseCartSum.data;
      setshoppingCartSum(responseCartSum.data);

      const secondApiResponse = await axiosInstance.get(
        `/Cart/GetWebsiteDiscount`
      );
      setWebsiteDiscount(secondApiResponse.data);

      if (formData && formData.shippingPrefrence === "CSCLedPickUp") {
        setshippingPrefrence("Free");
      } else if (
        formData &&
        formData.shippingPrefrence === "CSCLedFreeShip" &&
        cartSum === true
      ) {
        setshippingPrefrence("Free");
      } else {
        setshippingPrefrence("TBD");
      }

      const firstNameLastNameResponse = await axiosInstance.get(`/SFAccount`);
      const firstNameLastName =
        firstNameLastNameResponse.data.firstName +
        " " +
        firstNameLastNameResponse.data.lastName;
      setfirstNameLastName(firstNameLastName);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  // const showDeleteConfirmation = (id: any) => {
  //   setSelectedItem(id);
  //   setShowConfirmation(true);
  //   handleOpen();
  // };

  // const handleOpen = () => {
  //   setIsOpen(true);
  //   setShowModal(false);
  // };

  // const handleClose = () => {
  //   setIsOpen(false);
  // };

  // const handleDelete = () => {
  //   // Perform delete action
  //   console.log("Item deleted:", selectedItem);
  //   setShowConfirmation(false);
  //   callDeleteAPI(selectedItem);
  // };

  // const callDeleteAPI = async (itemId: any) => {
  //   try {
  //     const id = itemId;      
  //     await axiosInstance.get(`/Cart/DeleteCartItem?id=${id}`);
  //     dispatch(getCartData());
  //     dispatch(getCartListData());
  //     dispatch(getCartIndexListData());
  //     // Remove the deleted item from the local state
  //     fetchData();
  //   } catch (error) {
  //     console.error("Error deleting item:", error);
  //   }
  // };

  const checkClearanceQty = (): number => {
    const outofStockCount = ValidateTotalInventoryQtyCount(shoppingCartDetails, true);
    setValidateProductQty((prevState) => ({
      ...prevState,
      isClearanceValidate: outofStockCount > 0,
    }));
    return outofStockCount;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Call the onSubmit function from props with the form data
    handleFormSubmit();
  };
  

  

  const handleFormSubmit = async () => {
    var isValid = validateForm();
    console.log("Enter after validation method check");

    if (!isValid) {
    let discountValue = 0;
    if(discountRef.current != null){
      const discountText = discountRef.current.textContent;
      discountValue = parseFloat(discountText.match(/(\d+(\.\d+)?)/)[0]);
    }
  
    const shoppingCartRequest: ShoppingCartRequest = {
      confirmformData: {
        ifCentralPurchase: formData.ifCentralPurchase,
        shippingAddressDetailId: formData.shippingAddressDetails,
        purchaseOrderNumber: formData.purchaseOrderNumber,
        shippingCourier: formData.shippingCourier,
        selectedCustomerId: formData.selectedCustomerId,
        shippingPrefrence: formData.shippingPrefrence,
        discountValue: discountValue,
        freeShipping:
          formData.FreeShipping === undefined ? "" : formData.FreeShipping,
        notFreeShipping:
          formData.NotFreeShipping === undefined
            ? ""
            : formData.NotFreeShipping,
      },
      purchaseOrderFileData: {
        purchaseOrderFileName:
          formData.purchaseOrderFileData?.purchaseOrderFileName || "",
        purchaseOrderFileURL:
          formData.purchaseOrderFileData?.purchaseOrderFileURL || "",
      },
    };

    console.log("ShoppingCartRequest=>",shoppingCartRequest);
    console.log("loader started");
      setIsPageLoading(true);
      try {
        await axiosInstance
        .post(`/Cart/SaveShoppingCartByUserId`, shoppingCartRequest, {
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((response) => {
          console.log("Enter here after API call=>",response);
          console.log("response of API=>", response.data);
          if (response.data !== 0) {
            const orderNumber = response.data;
            navigate("/Cart/order-placed", { state: { orderNumber } });            
            setIsPageLoading(false);
            dispatch(getCartListData());
            dispatch(getCartData());
            dispatch(getCartIndexListData());
          }
        });
      }
      catch (error) {
        console.error("Error occurred while saving shopping cart", error);
        setIsPageLoading(false); // Ensure loader is stopped even if an error occurs
      }
     
    }
  };

  const validateForm = () => {
    console.log("Enter here for validation method");
    const newErrors: any = {};
    if (isChecked === false) {
      setIsAlertMessageDisplay(true);
      newErrors.alertMessage = "true";
    } else {
      setIsAlertMessageDisplay(false);
    }

    if (isClearanceModal === true) {
      setClearanceModal(true);
      setPlaceOrderButtonDisplay(true);
      newErrors.clearanceMessage = "true";
    } else {
      setClearanceModal(false);
      setPlaceOrderButtonDisplay(false);
    }

    if (isNotClearanceModal === true) {
     // newErrors.clearanceMessage = "true";
      setNotClearanceModal(true);
    } else {
      setNotClearanceModal(false);
    }

    setErrors(newErrors);
    logDataToBackend({
      message: "Form Validation Check",
      validationErrors: newErrors,
      isChecked,
      isClearanceModal,
      isNotClearanceModal,
      isOutOfStockOrNotForLog,
      validationStatus: Object.keys(newErrors).length !== 0 ? "Invalid" : "Valid"
    });
    return Object.keys(newErrors).length !== 0 ? true : false;
  };

// Function to log data to backend
  const logDataToBackend = async (logData: any) => {
    try {
      await axiosInstance.post('/Cart/shopping-cart', logData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log("Log data sent to backend.");
    } catch (error) {
      console.error("Failed to log data", error);
    }
  };

  const warningAlert=(title:string,htmlbody:string)=>{
    return(
      <div
      className={`bg-blue-50 text-csccolor px-4 py-3 rounded relative`}
      role="alert"
    >
      <strong className="font-bold">{title}</strong>
      <span
        className={`block sm:inline`}
        dangerouslySetInnerHTML={{ __html: htmlbody }}
      ></span>
    </div>
    );
  }

  return (
    <div className={`nc-ReviewOrder ${className}`} data-nc-id="ReviewOrder">
      <Helmet>
        <title>Review Order | CSC LED</title>
      </Helmet>

      {!isPageLoading ? (
        <main className="container py-16 lg:pt-12">
          <HeadingText1 className="mb-12">
            Review Order
          </HeadingText1>
          {/* <h2 className="text-3xl md:text-4xl font-semibold mb-12">
           Review Order
        </h2> */}
          <form onSubmit={handleSubmit}>
            <div className="w-full flex flex-wrap">
              <div className="flex-1">
                <div className="border mb-6 border-slate-200 dark:border-slate-700 rounded-xl ">
                  <div className="flex items-center p-6">
                    <img
                      src={InvoiveTo}
                      alt={`productImageNotfound`}
                      className="h-6 w-6 object-contain object-center"
                    />
                    <p className="ml-3 text-xl flex capitalize font-medium leading-6 text-csccolor">
                      invoice to
                    </p>
                  </div>

                  <div className="border-t border-slate-200 dark:border-slate-700 p-6 block">
                    <pre
                      style={{
                        fontFamily:
                          'var(--font-body), ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                      }}
                      className="text-base mb-1 text-black"
                    >
                      {customerInvoiceDetails}
                    </pre>
                  </div>
                </div>
                {formData.shippingPrefrence !== "CSCLedPickUp" &&
                formData.shippingAddressDetails !== 0 ? (
                  <div className="border my-6 border-slate-200 dark:border-slate-700 rounded-xl ">
                    <div className="flex items-center p-6">
                      <img
                        src={ShipTo}
                        alt={`productImageNotfound`}
                        className="h-6 w-6 object-contain object-center"
                      />
                      <p className="text-xl ml-3 flex capitalize font-medium leading-6 text-csccolor">
                        ship to
                      </p>
                    </div>
                    <div className="border-t border-slate-200 dark:border-slate-700 p-6 block">
                      <pre
                        style={{
                          fontFamily:
                            'var(--font-body), ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                        }}
                        className="text-base mb-1 text-black"
                      >
                        {shipToDetails}
                      </pre>
                    </div>
                  </div>
                ) : (
                  <div className="border my-6 border-slate-200 dark:border-slate-700 rounded-xl ">
                    <div className="flex items-center p-6">
                      <img
                        src={ShipTo}
                        alt={`productImageNotfound`}
                        className="h-6 w-6 object-contain object-center"
                      />
                      <p className="text-base ml-3 flex capitalize font-medium leading-6 text-gray-900">
                        ship to
                      </p>
                    </div>
                    <div className="border-t border-slate-200 dark:border-slate-700 p-6 block">
                      <pre
                        style={{
                          fontFamily:
                            'var(--font-body), ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                        }}
                        className="text-base mb-1 text-black"
                      >
                        Nowhere!
                        <br></br>
                        <br></br>
                        You indicated for this order that you will be picking it
                        up. We will catch you soon.
                      </pre>
                    </div>
                  </div>
                )}

                {formData.purchaseOrderNumber !== "" ||
                formData.purchaseOrderFileData !== "" ||
                formData.shippingCourier !== "" ? (
                  <div className="border my-6 border-slate-200 dark:border-slate-700 rounded-xl ">
                    <p className="text-xl flex p-6 capitalize font-medium leading-6 text-csccolor">
                      order details
                    </p>

                    <div className="border-t border-slate-200 dark:border-slate-700 p-6 block">
                      {formData &&
                      formData.purchaseOrderNumber !== null &&
                      formData.purchaseOrderNumber !== "" ? (
                        <div className="w-full flex flex-wrap">
                          <label className="capitalize font-medium">
                            Purchase Order Number:
                          </label>
                          <span className="px-1"></span>
                          <p className="text-black font-normal">
                            {formData && formData.purchaseOrderNumber}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}

                      {formData && formData.purchaseOrderFileData !== "" ? (
                        <div className="w-full pt-2 flex flex-wrap">
                          <label className="capitalize font-medium">
                            Purchase Order File:
                          </label>
                          <span className="px-1"></span>
                          <p className="text-indigo-800  font-normal">
                            <a
                              target="blank"
                              href={`${config.url.AWS_S3_URL}${formData.purchaseOrderFileData.purchaseOrderFileURL}`}
                              className="mr-2 sm:py-2.5
                                            py-2 my-1 
                                            "
                            >
                              {
                                formData.purchaseOrderFileData
                                  .purchaseOrderFileName
                              }
                            </a>
                          </p>
                        </div>
                      ) : (
                        ""
                      )}

                      {formData && formData.shippingCourier ? (
                        <div className="w-full pt-2 flex flex-wrap">
                          <label className="capitalize font-medium">
                            order notes:
                          </label>
                          <span className="px-1"></span>
                          <p className="text-black  font-normal capitalize">
                            {formData && formData.shippingCourier}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="flex-shrink-0 border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:lg:mx-12 2xl:mx-14 "></div>
              <div className="w-full lg:w-[36%] ">
                <h3 className="text-xl font-medium text-csccolor">
                  Order Summary
                </h3>
                {/* <p className="p-6 text-xl font-medium text-csccolor">
              Order summary
            </p> */}
                {shoppingCartDetails != null && shoppingCartDetails.length !== 0
                  ? shoppingCartDetails.map((item: any, index: any) => (
                      <div className="my-8 divide-y divide-slate-200/70 dark:divide-slate-700 ">
                        <div className="relative flex py-7 first:pt-0 last:pb-0">
                          <div className="relative h-32 w-24 sm:w-28 flex-shrink-0 overflow-hidden rounded-xl border border-gray-200 ">
                            {item.inventoryImagesLowRes != null &&
                            item.inventoryImagesLowRes.length !== 0 ? (
                              <img
                                className="h-full w-full object-contain object-center"
                                src={
                                  config.url.ERP_Domain +
                                  item.inventoryImagesLowRes[0].imageUrl
                                }
                                alt={`productImage`}
                              />
                            ) : (
                              <img
                                src={ImageNotFound}
                                alt={`productImageNotfound`}
                                className="h-full w-full object-contain object-center"
                              />
                            )}
                          </div>

                          <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
                            <div className="flex sm:justify-between justify-start items-start sm:flex-row flex-col flex-wrap">
                              <div className="flex-[1.5] ">
                                <h3 className="text-base text-gray-700 truncate font-semibold whitespace-normal">
                                  <Link
                                    to={`/product-details/${encodeURIComponent(
                                      item.invDTO.productNumber
                                    ).toUpperCase()}`}
                                  >
                                    {item.invDTO != null
                                      ? item.invDTO.productNumber
                                      : ""}
                                  </Link>
                                </h3>

                                <div className="mt-1.5 sm:mt-2.5 flex text-sm text-black dark:text-slate-300">
                                  <div className="flex items-center space-x-1.5">
                                    Unit Price{" "}
                                    <p className="mx-4 border-l h-full border-slate-300 dark:border-slate-700 "></p>
                                    <span>
                                      <CurrencyFormat
                                        value={item.price.toFixed(2)}
                                      />
                                    </span>
                                  </div>
                                </div>

                                <div className="mt-1.5 sm:mt-2.5 flex text-sm text-black dark:text-slate-300">
                                  <div className="flex items-center space-x-1.5">
                                    Quantity{" "}
                                    <p
                                      style={{ marginLeft: "22px" }}
                                      className="mx-4 border-l h-full border-slate-300 dark:border-slate-700 "
                                    ></p>
                                    <span>{item.qty}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="flex sm:mt-0 mt-3 justify-end">
                                <div className="inline-block">
                                  {/* <div className="flex items-center border-2 border-green-500 rounded-lg py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium">
                          <span className="text-green-500 !leading-none">
                            $74.00
                          </span>
                        </div> */}
                                  {/* calculate totalPrice of order */}
                                  <span hidden>
                                    {
                                      (totalCartPrice =
                                        totalCartPrice + item.totalPrice)
                                    }
                                  </span>
                                  <Prices price={item.totalPrice} />
                                </div>
                              </div>
                            </div>

                            <div className="flex mt-auto pt-4 items-end justify-between text-sm">
                              {/* <a
                                onClick={(event) => {
                                  event.preventDefault();
                                  showDeleteConfirmation(item.id);
                                }}
                                href="##"
                                className="relative z-10 flex items-center mt-3 font-medium text-primary-600 hover:text-primary-500 text-sm "
                              >
                                <span>Remove</span>
                              </a> */}

                            {item.invDTO.clearance ? (
                                <div>

                             {isOutOfStockInventoryTotalDTO(
                                                        item.qty,
                                                        item.inventoryQTYDTO.totalAvailableForSale,
                              ) && (
                                <div className={`${item.invDTO.clearance ? "text-red-500":"text-csccolor"} flex space-x-1`}>
                                  <ExclamationTriangleIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                  <span className="font-semibold">
                                    Out of stock{" "}
                                    {`${
                                      item.invDTO.clearance
                                        ? "and on clearance"
                                        : ""
                                    }`}
                                  </span>
                                </div>
                              )}
                                  </div>

                            ) : (
                              <div>

                            {isOutOfStock(
                                item.qty,
                                item.invDTO.abQty,
                                item.invDTO.bcQty,
                                item.invDTO.onQTY
                              ) && (
                                <div className={`${item.invDTO.clearance ? "text-red-500":"text-csccolor"} flex space-x-1`}>
                                  <ExclamationTriangleIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                  <span className="font-semibold">
                                    Out of stock{" "}
                                    {`${
                                      item.invDTO.clearance
                                        ? "and on clearance"
                                        : ""
                                    }`}
                                  </span>
                                </div>
                              )}
                                  </div>
                            )}
                             
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  : ""}

                {shoppingCartSum === true ? (
                  <div className="w-full my-6 border-t-1 py-3 border p-3 border-slate-200 dark:border-slate-700 rounded-xl overflow-hidden z-0">
                    <div className="flex items-center">
                      <i className="text-4xl object-contain object-center mr-2 fi fi-rr-piggy-bank"></i>
                      <p className="text-black">
                        Your order qualifies for free shipping!
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="w-full my-6 border-t-1 py-3 border p-3 border-slate-200 dark:border-slate-700 rounded-xl overflow-hidden z-0">
                    <div className="flex items-center">
                      <i className="text-4xl object-contain object-center mr-2 fi fi-rr-piggy-bank"></i>
                      <p className="text-black">
                        We can provide <b>free nationwide shipping</b> for
                        orders with a <b>minimum subtotal of $250</b> and a
                        shipping address to a partnered distributor's location.
                      </p>
                    </div>
                  </div>
                )}
                <div className="text-sm text-black">
                  <div className="flex justify-between py-1.5">
                    <span>Subtotal</span>
                    <span className="font-semibold dark:text-slate-200">
                      <CurrencyFormat value={totalCartPrice.toFixed(2)} />
                    </span>
                  </div>

                  {websiteDiscount === true ? (
                    <div className="text-csccolor-green flex justify-between py-1.5">
                      <span hidden id="discountSpan" ref={discountRef}>
                        {(Discount = totalCartPrice * 0.02)}
                      </span>
                      <span className="font-semibold capitalize">
                        2% online order discount
                      </span>
                      <span className="font-semibold ">
                        {" "}
                        -<CurrencyFormat value={Discount.toFixed(2)} />
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="flex text-black justify-between py-1.5">
                    <span>Shipping</span>
                    <span className="font-semibold uppercase dark:text-slate-200">
                      {shippingPrefrence}
                    </span>
                  </div>
                  <div className="flex text-black justify-between py-1.5">
                    {formData != null &&
                    formData.shippingPrefrence !== "CSCLedPickUp" ? (
                      <span>
                        {" "}
                        <TaxCodeComponent shippingCode={shippingCodeName} />
                      </span>
                    ) : (
                      "Tax"
                    )}

                    <span className="font-semibold uppercase text-slate-900 dark:text-slate-200">
                      tbd
                    </span>
                  </div>
                  <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base py-1.5">
                    <span>Estimated CDN</span>
                    <span hidden>
                      {(totalCartPrice = totalCartPrice - Discount)}
                    </span>
                    <span>
                      {" "}
                      <CurrencyFormat value={totalCartPrice.toFixed(2)} />
                    </span>
                  </div>
                </div>
                <div className="w-full mt-6 flex">
                  <div className="flex h-6 items-center">
                    <input
                      id="comments"
                      aria-describedby="comments-description"
                      name="comments"
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      className="h-5 w-5 rounded border-4 border-red-700 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div className="ml-3 text-base">
                    <span id="comments-description" className="text-gray-700">
                      <strong>I {firstNameLastName} </strong>confirm that all of
                      the information on this order confirmation is correct, and
                      should be used for this order. The information shown here
                      should take priority over the information provided in my
                      purchase order file upload (if applicable).
                    </span>
                  </div>
                </div>
                {isAlertMessageDisplay === true ? (
                  <div className="mt-6 w-full">
                    <AlertMessage message="You must confirm the order's information by clicking on the red checkbox above, before you can place your order." />
                  </div>
                ) : (
                  ""
                )}

                {/**Clearance validation message */}
                {validateProductQty.isClearanceValidate && (
                  <div className="mt-6 w-full">
                    <AlertMessage
                      className="mt-4"
                      message={`An item in your cart is on clearance and we do not have enough in stock.
                              <br /><br />To proceed with this order, you either need to remove this item or reduce the quantity in your cart.`}
                    />
                  </div>
                )}

                {isNotClearanceModal && (
                  <div className="mt-6 w-full">
                     {warningAlert("Just a quick FYI! ",`An item in your cart is out of stock and will be placed on back order.
                              <br /><br />Please contact <a href="mailto:orders@csc-led.com"><b>orders@csc-led.com</b></a> if you have any questions.`)}
                  </div>
                )}

                <div className="mt-6 w-full">
                  {shoppingCartDetails != null &&
                  shoppingCartDetails.length !== 0 ? (
                    <ButtonCSCLed
                      type="submit"
                      disabled={isPlaceOrderButtonDisplay}
                      className="capitalize w-full"
                    >
                      place order
                    </ButtonCSCLed>
                  ) : (
                    <ButtonCSCLed
                      type="submit"
                      disabled={true}
                      className="capitalize w-full"
                    >
                      place order
                    </ButtonCSCLed>
                  )}
                </div>
              </div>
              {/* {showConfirmation && (
                <Transition appear show={isOpen} as={Fragment}>
                  <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={handleClose}
                  >
                    <Backdrop isOpen={isOpen} />
                    <div className="fixed inset-0 overflow-y-auto">
                      <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <DialogPanel
                          title={"Wait a Second!"}
                          message={
                            "Are you sure you want to remove this item from your cart?"
                          }
                          onConfirm={handleDelete}
                          handleClose={handleClose}
                        />
                      </div>
                    </div>
                  </Dialog>
                </Transition>
              )} */}
            </div>
          </form>
        </main>
      ) : (
        <CartPageLoader isLoading={isPageLoading} />
      )}
    </div>
  );
};

export default ReviewOrder;
