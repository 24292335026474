import Rewards_Infographic from "images/rewards/Rewards_Infographic-01.png";
import Rewards_Infographic_Mobile_Version from "images/rewards/Rewards_Infographic_Mobile_Version-01.png";

const HowThisWorkContainer = () => {
  return (
    <div className="w-full shadow-lg rounded-lg  my-14 sm:mt-14 mt-6">
      <h2 className="font-medium p-6 text-xl border-b border-gray-200 text-gray-800 halloween:text-black halloween:border-white sm:text-3xl md:text-2xl" style={{fontFamily: 'Poppins, sans-serif'}}>
        How does this work?
      </h2>
      <div className="mt-6 md:flex hidden">
        <img
          className="h-full w-full object-cover object-left"
          src={Rewards_Infographic}
          alt="Rewards Banner"
        />
      </div>
      <div className="mt-6 md:hidden block">
        <img
          className="h-full w-full object-cover object-left"
          src={Rewards_Infographic_Mobile_Version}
          alt="Mobile Image"
        />
      </div>
    </div>
  );
};

export default HowThisWorkContainer;
