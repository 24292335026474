import axiosInstance from "api/AxiosInstance";
import { BaseProp } from "entities/BaseProp";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonCSCLed from "shared/Button/ButtonCSCLed";
import { getUserLoginData } from "utils/auth";
import { config } from "utils/Constance";

interface RewardShopBannerProps extends BaseProp {
  userPoints?: string | null;
  isRewardAccess?: boolean | null;
}

interface RewardShopBannerConfig {
  bannerTitle: string;
  bannerSubheader: string;
  bannerAwsPath: string;
  mobileBannerAwsPath: string;
}

const RewardShopBanner: FC<RewardShopBannerProps> = ({
  userPoints,
  isRewardAccess,
}) => {
  const userData = getUserLoginData();
  const navigate = useNavigate();
  const [rewardShopBannerConfig, setRewardShopBannerConfig] =
    useState<RewardShopBannerConfig | null>(null);

  const fetchRewardShopBannerConfig = async () => {
    const response = await axiosInstance
      .get("/Rewards/getRewardRewardShopBannerConfig")
      .then((response) => {
        setRewardShopBannerConfig(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchRewardShopBannerConfig();
  }, []);

  const renderUserRewardPoints = () => {
    return (
      <span className="nc-UserRewardPoints inline-flex capitalize items-center rounded-md bg-yellow-500 px-4 sm:py-2.5 py-2 sm:text-base text-xs font-medium text-gray-900">
        Your available reward points: {userPoints}
      </span>
    );
  };

  const renderUserSignUp = () => {
    return (
      <div className="nc-UserSignUp flex w-full">
        <ButtonCSCLed className="capitalize my-1 text-xs sm:text-base w-1/2 sm:py-3 text-center" onClick={()=>handleSignUpClick()}>
          Sign Up Today
        </ButtonCSCLed>
      </div>
    );
  };

  const handleSignUpClick=()=>{
    if(!userData){
      navigate("/rewards/signup");
    }
    else if(userData && !isRewardAccess){
      navigate("/rewards?requestAccess=true");
    }
  }

  const renderContent = () => {
    if (userData && userPoints && isRewardAccess) {
      return renderUserRewardPoints();
    }
    if (!isRewardAccess || !userData) {
      return renderUserSignUp();
    } else {
      return null;
    }
  };

  return (
    <div className="sm:container p-0 sm:mt-10 mt-0">
      <div className="bg-white flex w-full relative items-center justify-left Rewards_shop_banner Rewards_hero md:py-24 sm:py-6 py-2 px-6">
        <div className="hidden sm:block left-0 absolute md:h-full h-auto w-full">
          <img
            className="h-auto min-h-full border hero__image rewards_shop_hero_img border-gray-200 w-auto min-w-full object-fill object-left"
            src={`${config.url.AWS_S3_URL}${rewardShopBannerConfig?.bannerAwsPath}`}
            alt="Rewards Shop Banner"
          />
        </div>
        <div className="md:hidden block">
          <img
            className="w-auto h-auto top-0 left-0 object-fill min-w-full absolute min-h-full"
            src={`${config.url.AWS_S3_URL}${rewardShopBannerConfig?.mobileBannerAwsPath}`}
            alt="Mobile Image"
            style={{ objectFit: "fill" }}
          />
        </div>

        <div className="relative z-1 items-start flex flex-col">
          <h1 className="md:text-3xl sm:text-xl text-lg font-medium tracking-tight text-gray-900 lg:text-4xl">
            {rewardShopBannerConfig?.bannerTitle}
          </h1>
          {rewardShopBannerConfig?.bannerSubheader && (
            <p className="lg:my-6 w-full sm:my-3 my-1 lg:text-2xl md:text-xl text-xs sm:text-base text-gray-700">
              {rewardShopBannerConfig?.bannerSubheader}
            </p>
          )}
          {isRewardAccess != null && renderContent()}
        </div>
      </div>
    </div>
  );
};

export default RewardShopBanner;
