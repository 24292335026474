import { useEffect } from "react";

const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GA_TRACKING_ID;
const ENV = process.env.REACT_APP_ENV; // Read environment variable

const GoogleAnalytics = () => {
  useEffect(() => {
    // const GA_TRACKING_ID = "G-SPXJGVZEG2";

      // Only run in production
      if (ENV !== "production" || !GOOGLE_ANALYTICS_ID) {
        console.log("Google Analytics is disabled in non-production environments.");
        return;
      }

       // Prevent duplicate script loading
      if (document.getElementById("google-analytics-script")) {
        return;
      }
   
    
    // Inject the Google Analytics script
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`;
    document.head.appendChild(script);

    // Initialize Google Analytics
    const scriptInit = document.createElement("script");
    scriptInit.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${GOOGLE_ANALYTICS_ID}');
    `;
    document.head.appendChild(scriptInit);
  }, []);

  return null;
};

export default GoogleAnalytics;
