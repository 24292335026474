import React, { FC, useState, useEffect, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { Helmet } from "react-helmet-async";
import { config } from "utils/Constance";
import Rewards_Large_Tiles_01 from "images/rewards/Rewards_Large_Tiles-01.jpg";
import Rewards_Large_Tiles_02 from "images/rewards/Rewards_Large_Tiles-02.jpg";
import { Link, useNavigate } from "react-router-dom";
import Accordion from "components/Accordian";
import axiosInstance from "api/AxiosInstance";
import Received_Coin_Icon from "images/rewards/Received_Coin_Icon.gif";
import Request_Access from "images/rewards/Request_Access.png";
import Request_Sent from "images/rewards/Request_Sent.png";
import Input from "shared/Input/Input";
import PageLoader from "components/CSCLedLoader/PageLoader";
import ButtonCSCLed from "shared/Button/ButtonCSCLed";
import AlertMessage from "./AlertMessage";
// import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { useAppSelector } from "store/hooks";
import "./Rewards.css";
import HowThisWorkContainer from "./HowThisWorkContainer";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface RewardsProps {
  className?: string;
}

interface RewardSetting {
  id: number;
  bannerAwsPath: string;
  bannerText: string;
  mobileBannerAwsPath: string;
}

interface RewardFaq {
  id: number;
  question: string;
  answer: string;
}
const previewStyle: React.CSSProperties = {
  maxWidth: "200px",
  maxHeight: "200px",
  marginTop: "10px",
  fontFamily: 'Poppins, sans-serif'
};
const Rewards: FC<RewardsProps> = ({ className = "" }) => {
  const user = useAppSelector((state: RootState) => state.auth);
  const [rewardSetting, setRewardSetting] = useState<RewardSetting | null>(
    null
  );
  const [rewardFaqs, setRewardFaqs] = useState<RewardFaq[]>([]);
  const [IsRewardsProgram, setIsRewardsProgram] = useState(false);
  const rewardsUpload = useRef<HTMLDivElement>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [errorFile, setErrorFile] = useState("");
  const [note, setNote] = useState("");
  const [rewardsId, setRewardsId] = useState(0);
  const [receiptNumber, setReceiptNumber] = useState("");
  const navigate = useNavigate();
  const [IsPromocodeExist, setIsPromocodeExist] = useState(null);
  const [confirmationMsg, setconfirmationMsg] = useState("");
  const [codePromo, SetCodePromo] = useState("");
  const isPreviewable = (file: File) => {
    const extensions = [
      "pdf",
      "csv",
      "xls",
      "xlsx",
      "doc",
      "docx",
      "txt",
      "zip",
    ];
    const extension = file.name.split(".").pop()?.toLowerCase();
    return !extensions.includes(extension || "");
  };
  const [IsRewardsAccessRequestSent, setIsRewardsAccessRequestSent] =
    useState(null);
  const [UploadbuttonClicked, setUploadButtonClicked] = useState(false);
  const [SigmeupbuttonClicked, setSigmeupButtonClicked] = useState(false);
  const [loading, setLoading] = useState(true);

  const getQueryParam = (param: string) => {
    const queryString = window.location.search;
    const query = new URLSearchParams(queryString);
    return query.get(param);
  };
 
  const onDrop = (acceptedFiles: File[]) => {
    setFiles(acceptedFiles);
    setErrorFile("");
  };

  const handleRemove = (indexToRemove: number) => {
    setFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
    setErrorFile(
      "You need to attach a packing slip or invoice before you can submit your proof of purchase."
    );
  };
  const handleNoteChange = (e: any) => {
    setNote(e.target.value);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  //const { getRootProps }  = useDropzone({ onDragOver });

  const fetchData = async () => {
    const response = await axiosInstance
      .get("/Rewards/RewardSetting")
      .then((response) => {
        setRewardSetting(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchRewardFAQsData = async () => {
    const response = await axiosInstance
      .get("/Rewards/RewardFAQs")
      .then((response) => {
        setRewardFaqs(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchRewardsProgram = async () => {
    const response = await axiosInstance
      .get(`/Rewards/RewardProgram`)
      .then((response) => {
        setIsRewardsProgram(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    setSigmeupButtonClicked(false);
    fetchData();
    fetchRewardFAQsData();
    fetchRewardsProgram();
  }, []);

  const scrollToDiv = () => {
    if (rewardsUpload.current) {
      const targetOffset = rewardsUpload.current.offsetTop - 85;
      window.scrollTo({ top: targetOffset, behavior: "smooth" });
    }
  };

  //Scroll to request access section.
  const requestAccess = getQueryParam("requestAccess");
  if(requestAccess){
    scrollToDiv();
  }

  const handleSubmit = async () => {
    if (!UploadbuttonClicked) {
      if (files.length === 0) {
        setErrorFile(
          "You need to attach a packing slip or invoice before you can submit your proof of purchase."
        );
        return;
      }
      try {
        setUploadButtonClicked(true);
        const formData = new FormData();
        formData.append("file", files[0]);
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            accept: "*/*",
          },
          params: {
            CustomerProvidedNote: note,
          },
        };
        const response = await axiosInstance
          .post("/Rewards/AddRewardsReceipts", formData, config)
          .then((response) => {
            setRewardsId(response.data);
            fetchGetReceiptNumber(response.data);
            setTimeout(() => {
              setLoading(false);
            }, 3000);
          });
      } catch (error) {
        console.error("API Error:", error);
      }
    }
  };

  const fetchGetReceiptNumber = async (Id: any) => {
    const response = await axiosInstance
      .get(`/Rewards/GetRewardsReceiptById?Id=${Id}`)
      .then((response) => {
        setReceiptNumber(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleuploadPOP = () => {
    setUploadButtonClicked(false);
    setLoading(true);
    setFiles([]);
    setIsRewardsProgram(true);
    setRewardsId(0);
    setNote("");
    navigate("/rewards");
  };

  const handleApplyCode = async (e: any) => {
    try {
      if (codePromo) {
        await axiosInstance
          .get(`/Rewards/CheckRewardsPromos?codePromo=${codePromo}`)
          .then((response) => {
            setIsPromocodeExist(response.data.isPromocodeExist);
            setconfirmationMsg(response.data.confirmationMsg); // Log the response data if needed
          });
      } else {
        setconfirmationMsg("");
      }
    } catch (error: any) {
      console.error("API Error:", error);
    }
  };
  const handleCodePromoChange = (value: any) => {
    SetCodePromo(value.target.value);
  };

  const handleRewardSignUp = async (e: any) => {
    if (!SigmeupbuttonClicked) {
      try {
        setSigmeupButtonClicked(true);
        await axiosInstance
          .get(`/Rewards/RequestRewardsProgram?codePromo=${codePromo}`)
          .then((response) => {
            setIsRewardsAccessRequestSent(response.data);
            setTimeout(() => {
              setLoading(false);
            }, 3000);
          });
      } catch (error: any) {
        console.error("API Error:", error);
      }
    }
  };
  const handleRewardsShop = () => {
    navigate("/rewards/rewards-shop");
  };

  const RewardButtons = () => {
    return (
      <div className="nc-RewardButtons flex w-full" style={{fontFamily: 'Poppins, sans-serif'}}>
        <ButtonCSCLed
          className="capitalize my-1 text-xs sm:text-base w-1/2 sm:py-3 text-center"
          onClick={scrollToDiv}
        >
          Redeem Points
        </ButtonCSCLed>
        <button
          className="capitalize my-1 ml-4 focus:ring-offset-2 sm:text-base text-xs w-1/2 py-3 text-center text-gray-900 bg-yellow-400 
                    active:bg-yellow-500 
                    focus:outline-none focus:ring focus:ring-yellow-400 focus:bg-yellow-500 rounded-full  
                    hover:bg-yellow-500 hover:shadow-xl"
          onClick={handleRewardsShop}
        >
          Visit Rewards Shop
        </button>
      </div>
    );
  };

  return (
    <div className={`nc-Rewards ${className}`} data-nc-id="Rewards">
      <Helmet>
        <title>Rewards Page | CSC LED</title>
      </Helmet>
      <div className="w-full">
        <div className="container sm:mt-14 mt-0">
          <div className=" bg-white flex w-full relative items-center justify-left Rewards_hero sm:py-24 py-6 px-6">
            <div className="md:flex hidden left-0 absolute h-full w-full">
              <img
                className="h-auto min-h-full border hero__image  border-gray-200 w-auto min-w-full object-fill object-left"
                src={`${config.url.AWS_S3_URL}${rewardSetting?.bannerAwsPath}`}
                alt="Rewards Banner"
              />
            </div>

            <div className="md:hidden block">
              <img
                className="w-auto h-auto top-0 left-0 object-fill min-w-full absolute min-h-full"
                src={`${config.url.AWS_S3_URL}${rewardSetting?.mobileBannerAwsPath}`}
                alt="Mobile Image"
                style={{ objectFit: "fill" }}
              />
            </div>
            <div className="relative z-1 items-start flex flex-col">
              <h2 className="md:text-3xl text-xl font-medium tracking-tight text-blue-800 lg:text-5xl">
                CSC LED Rewards
              </h2>
              <p className="lg:my-6 sm:my-3 sm:w-full hero_paragraph my-2 lg:text-2xl md:text-xl text-xs text-gray-800" style={{fontFamily: 'Poppins, sans-serif'}}>
                {rewardSetting?.bannerText}
              </p>
              <div className="hidden sm:block w-full">
                <RewardButtons />
              </div>
            </div>
          </div>
          <div className="md:hidden block justify-center my-8">
            <RewardButtons />
          </div>
          {/**How this work component */}
          <HowThisWorkContainer />
          {user.isAuthenticated && IsRewardsProgram && rewardsId == 0 && (
            <div className="w-full shadow-lg rounded-lg my-14">
              <h2
                ref={rewardsUpload}
                className="font-medium p-6 text-xl border-b border-gray-200 text-gray-800 halloween:text-black halloween:border-white sm:text-3xl md:text-2xl"
                style={{fontFamily: 'Poppins, sans-serif'}}
              >
                Upload a Packing Slip or Invoice
              </h2>
              <div className="w-full md:w-3/4 p-4 px-6">
                <div
                  {...getRootProps()}
                  className="flex items-center justify-center w-full"
                >
                  <input {...getInputProps()} />
                  <label className="flex flex-col px-6 items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                    <div className="flex flex-col items-center justify-center pt-10 pb-10">
                      <svg
                        className="w-10 h-10 mb-4 text-gray-500 dark:text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 16"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                        />
                      </svg>
                      <div className="max-w-sm mx-auto">
                        {isDragActive ? (
                          <div className="mb-4 text-center">
                            <p className="mb-2 text-lg text-gray-500 dark:text-gray-400 font-semibold" style={{fontFamily: 'Poppins, sans-serif'}}>
                              Click here to upload your packing slip or invoice.
                            </p>
                            <p className="text-sm text-gray-500 dark:text-gray-400" style={{fontFamily: 'Poppins, sans-serif'}}>
                              Please ensure your picture shows the FULL packing
                              slip or invoice.
                            </p>
                          </div>
                        ) : (
                          <div className="mb-4 text-center">
                            <p className="mb-2 text-lg text-gray-500 dark:text-gray-400 font-semibold" style={{fontFamily: 'Poppins, sans-serif'}}>
                              Click here to upload your packing slip or invoice.
                            </p>
                            <p className="text-sm text-gray-500 dark:text-gray-400" style={{fontFamily: 'Poppins, sans-serif'}}>
                              Please ensure your picture shows the FULL packing
                              slip or invoice.
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </label>
                </div>

                <div>
                  {files.map((file, index) => (
                    <div key={index}>
                      {!isPreviewable(file) ? (
                        <>
                          <h3 style={{ marginTop: "10px" }}>{file.name}</h3>
                          <button
                            className="block text-csccolor"
                            onClick={() => handleRemove(index)}
                          >
                            Remove upload
                          </button>
                        </>
                      ) : (
                        <>
                          <img
                            src={URL.createObjectURL(file)}
                            alt={file.name}
                            style={previewStyle}
                            
                          />
                          <h3 style={{fontFamily: 'Poppins, sans-serif'}}>{file.name}</h3>
                          <button
                            className="block text-blue-700 font-medium"
                            onClick={() => handleRemove(index)}
                            style={{fontFamily: 'Poppins, sans-serif'}}
                          >
                            Remove upload
                          </button>
                        </>
                      )}
                    </div>
                  ))}
                </div>
                {errorFile && (
                  <div className="mt-6 w-full" style={{fontFamily: 'Poppins, sans-serif'}}>
                    <AlertMessage message={errorFile} />
                  </div>
                  // <span
                  //   className="block text-base p-2 sm:p-4 sm:px-0"
                  //   style={{ color: "#fd397a" }}
                  // >
                  //   {errorFile}
                  // </span>
                )}
              </div>
              <div className="form-group -mt-3 -mb-5">
                <div className="w-full md:w-3/4 p-4 px-6">
                  <label className="block text-base text-black-600 halloween:text-black mb-2" style={{fontFamily: 'Poppins, sans-serif'}}>
                    Anything we should know regarding the packing slip or
                    invoice?
                  </label>
                  <textarea
                    id="CustomerProvidedNote"
                    name="CustomerProvidedNote"
                    className="form-control border rounded-md focus:outline-none focus:ring  w-full px-4 py-2 text-base leading-6 text-gray-700 placeholder-gray-400 bg-custom-grey border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    maxLength={300}
                    rows={3}
                    cols={80}
                    spellCheck={false}
                    value={note}
                    onChange={handleNoteChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="w-full md:w-3/4 p-4 px-6">
                  <p className="text-base text-black-600 halloween:text-black" style={{fontFamily: 'Poppins, sans-serif'}}>
                    It may take up to two business days for your reward points
                    to appear in your website account.
                  </p>
                </div>
              </div>
              <div className="form-group">
                <div className="w-full md:w-3/4 p-4 px-6 flex" style={{fontFamily: 'Poppins, sans-serif'}}>
                  <ButtonCSCLed
                    type="submit"
                    className="capitalize button-container"
                    onClick={handleSubmit}
                    disabled={UploadbuttonClicked}
                  >
                    Upload my Proof of Purchase
                  </ButtonCSCLed>
                  {/* <button
                      type="submit"
                      onClick={handleSubmit}
                      disabled={UploadbuttonClicked}
                      className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600 dark:focus:ring-offset-0 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 shadow-xl"
                    >
                      Upload my Proof of Purchase
                    </button> */}
                </div>
                </div>
            </div>
          )}
          {!user.isAuthenticated && rewardsId == 0 && (
            <div className="w-full">
              <div
                ref={rewardsUpload}
                className="space-y-12 lg:grid lg:grid-cols-2 lg:gap-x-6 lg:space-y-0"
              >
                <div
                  className="shadow-lg rounded-lg"
                  style={{ border: "15px solid #fff" }}
                >
                  <div className="relative overflow-hidden bg-custom-grey min-h-full max-w-full">
                    <Link to="/rewards/signup">
                      <img
                        className="h-full max-h-full w-full object-cover object-center"
                        src={Rewards_Large_Tiles_01}
                        alt="Rewards Banner"
                      />
                    </Link>
                  </div>
                </div>

                <div className="shadow-lg rounded-lg border-white">
                  <div
                    className="shadow-lg rounded-lg"
                    style={{ border: "15px solid #fff" }}
                  >
                    <Link to="/rewards/rewardslogin">
                      <img
                        className="h-full max-h-full w-full object-cover object-center"
                        src={Rewards_Large_Tiles_02}
                        alt="Rewards Banner"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}

          {user.isAuthenticated &&
            IsRewardsProgram &&
            rewardsId != 0 &&
            (loading ? (
              <PageLoader />
            ) : (
              <div className="w-full shadow-lg rounded-lg my-14 pb-2">
                <h2
                  style={{fontFamily: 'Poppins, sans-serif'}}
                  ref={rewardsUpload}
                  className="pt-mobile font-medium p-6 text-xl border-b border-gray-200 text-green-800 sm:text-3xl md:text-2xl"
                >
                  Proof of Purchase Received!
                </h2>
                <div className="flex flex-col lg:flex-row">
                  <div className="lg:w-2/3 xs:w-full p-4 px-6">
                    <div className="mb-2 line-height-desktop md:line-height-mobile mt-mobile">
                      <p className="mobile_mb">
                        We have received your proof of purchase and are
                        processing it now.
                      </p>
                      <p className="mobile_mb">
                        Your proof of purchase number is: <b>{receiptNumber}</b>
                      </p>
                      <p className="mobile_mb">
                        It may take 1-2 business days before your reward points
                        appear in your account.
                      </p>
                      <p className="mobile_mb">
                        Should you have any questions, please do not hesitate to
                        reach out to{" "}
                        <Link
                          to={`mailto:${config.emails.EmailMarketing}`}
                          className="text-csccolor"
                        >
                          {config.emails.EmailMarketing}
                        </Link>
                      </p>
                      <p className="mobile_mb">
                        {" "}
                        Thank you for supporting CSC LED!
                      </p>
                      <p>The CSC LED Team</p>
                    </div>
                    <div className="form-group btn-mt-mobile">
                      <div className="w-full md:w-3/4">
                        <ButtonCSCLed
                          type="submit"
                          className="capitalize"
                          onClick={handleuploadPOP}
                        >
                          Click here to upload another
                        </ButtonCSCLed>
                        {/* <button
                          type="button"
                          onClick={handleuploadPOP}
                          className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 shadow-xl"
                        >
                          Click here to upload another
                        </button>                      */}
                      </div>
                    </div>
                  </div>
                  <div className="lg:w-1/3 mt-mobile xs:w-full order-first lg:order-last rewardsCoinIcon-mt">
                    <img
                      className="object-contain h-80 rewards-coin"
                      src={Received_Coin_Icon}
                      alt="Rewards Coin Icon"
                    />
                  </div>
                </div>
              </div>
            ))}

          {user.isAuthenticated &&
            IsRewardsProgram == false &&
            rewardsId == 0 &&
            IsRewardsAccessRequestSent == null && (
              <div className="w-full shadow-lg rounded-lg my-14">
                <h2
                  ref={rewardsUpload}
                  className="pt-mobile font-medium p-6 text-xl border-b border-gray-200 text-csccolor sm:text-3xl md:text-2xl"
                >
                  Request Access
                </h2>
                <div className="flex flex-wrap">
                  <div className="lg:w-2/3 xs:w-full p-4 px-6">
                    <div className="mb-2 line-height-desktop md:line-height-mobile">
                      <p className="mobile_mb">
                        The CSC LED rewards program is only for{" "}
                        <b>contractors</b> at this time.
                      </p>
                      <p>
                        If you are a contractor and would like to start
                        collecting points, click the button below.
                      </p>
                    </div>
                    <div className="flex flex-wrap mb-2">
                      <div className="w-full lg:w-5/12">
                        <div className="form-group">
                          <label className="block">
                            <span className="text-neutral-800 dark:text-neutral-200">
                              Promo Code
                            </span>
                            <Input
                              type="text"
                              id="Code"
                              maxLength={100}
                              className="mt-1"
                              onChange={handleCodePromoChange}
                            />
                          </label>
                        </div>
                        <div className="mt-2">
                          {confirmationMsg && IsPromocodeExist && (
                            <span className="block text-green-900">
                              {confirmationMsg}
                            </span>
                          )}
                          {confirmationMsg && !IsPromocodeExist && (
                            <span className="block text-red-900">
                              {confirmationMsg}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="lg:w-3/12 lg:px-3">
                        <div className="form-group lg:px-3 mt-7">
                          <button
                            type="button"
                            className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600 dark:focus:ring-offset-0 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 shadow-xl"
                            onClick={handleApplyCode}
                          >
                            Apply Code
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="signme_mb">
                      <button
                        type="button"
                        className="text-white bg-csccolor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600 dark:focus:ring-offset-0 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 shadow-xl mt-2"
                        disabled={SigmeupbuttonClicked}
                        onClick={handleRewardSignUp}
                      >
                        Sign me up!
                      </button>
                    </div>
                  </div>
                  <div className="lg:w-1/3 flex xs:w-full p-4">
                    <img
                      alt="Rewards Request Access"
                      src={Request_Access}
                      className="Request_Access md:flex hidden"
                    />
                    <img
                      alt="Rewards Request Access"
                      src={Request_Access}
                      className="Request_Access_mobile md:hidden block"
                    />
                  </div>
                </div>
              </div>
            )}

          {user.isAuthenticated &&
            IsRewardsProgram == false &&
            rewardsId == 0 &&
            IsRewardsAccessRequestSent &&
            (loading ? (
              <PageLoader />
            ) : (
              <div className="w-full shadow-lg rounded-lg my-14">
                <h2
                  ref={rewardsUpload}
                  className="pt-mobile font-medium p-6 text-xl border-b border-gray-200 text-csccolor sm:text-3xl md:text-2xl"
                >
                  Rewards Program Request Received!
                </h2>
                <div className="flex flex-wrap p-4">
                  <div className="lg:w-2/3 xs:w-full p-2 px-6 request_sent_text_mobile">
                    <div className="mb-2 leading-8 md:line-height-mobile">
                      <p className="mobile_mb">
                        You will receive an email within{" "}
                        <b> 1-2 business days </b> once your account is ready to
                        start collecting points.
                      </p>
                      <p className="p_request_received">
                        Thank you for supporting CSC LED,
                        <br />
                        The CSC LED Team
                      </p>
                    </div>
                  </div>
                  <div className="lg:w-1/3 flex items-center xs:w-full">
                    <img
                      alt="Rewards Request Sent"
                      src={Request_Sent}
                      className="request_sent md:flex hidden"
                    />
                    <img
                      alt="Rewards Request Sent"
                      src={Request_Sent}
                      className="request_sent_mobile md:hidden block"
                    />
                  </div>
                </div>
              </div>
            ))}

          <div className="w-full shadow-lg rounded-lg my-14">
            <h2 className="font-medium p-6 text-xl border-b border-gray-200 text-gray-800 halloween:text-black sm:text-3xl md:text-2xl">
              Frequently Asked Questions
            </h2>
            <div className="w-full">
              {rewardFaqs &&
                rewardFaqs.map((accordion: RewardFaq, index: any) => (
                  <Accordion
                    key={accordion.id}
                    question={accordion.question}
                    answer={accordion.answer}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rewards;
function getQueryParam(arg0: string) {
  throw new Error("Function not implemented.");
}

