import React, { useEffect } from "react";
import MyRouter from "routers/index";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { Provider } from "react-redux";
import { store } from "store/store";
import axiosInstance from "api/AxiosInstance";
import { ThemeProvider, useTheme } from "hooks/useTheme";
import { UserStateProvider } from "hooks/useUserState";
import { BackgroundProvider } from "contexts/BackgroundContext";
import HelmetComponent from "components/HelmetComponent/HelmetComponent";
import { useVersionCheck } from "hooks/useVersionCheck";
import GoogleAnalytics from "components/GoogleAnalytics";
library.add(fab, fas, far);

function App() {
  useVersionCheck();

  return (
    <HelmetProvider>
      {/* Title & meta data */}
      <HelmetComponent
        title="CSC LED | Innovative LED Lighting Solutions for All Industries"
        metaDescription="Discover CSC LED's wide range of sustainable and high-performance LED lighting products. From commercial to industrial solutions, we provide energy-efficient lighting designed for your needs. Learn more about our products and expertise."
        metaKeywords="CSC LED lighting solutions, Energy-efficient LED lights, Sustainable lighting products, Commercial LED lighting, Industrial LED solutions, LED lighting expertise, High-performance LED products, Innovative lighting technology, LED lighting for all industries"
      />
      {/* Google Analytics Component */}
      <GoogleAnalytics />  
      <Provider store={store}>
        <ThemeProvider>
          <UserStateProvider>
            <BackgroundProvider>
              <MainApp />
            </BackgroundProvider>
          </UserStateProvider>
        </ThemeProvider>
      </Provider>
    </HelmetProvider>
  );
}

const MainApp = () => {
  const { setAssignTheme } = useTheme();

  const fetchData = async () => {
    const response = await axiosInstance
      .get("/Home/getSeasonalTheme")
      .then((response) => {
        toggleTheme(response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const toggleTheme = (response: any) => {
    let theme = "light"; //Default theme
    if (response.data.data.isseasonalThemeEnable) {
      theme = "halloween";
    }
    setAssignTheme(theme);
  };

  return <MyRouter />;
};

export default App;
