import React, { FC, useState, useEffect, Fragment, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import NcInputNumber from "components/NcInputNumber";
import Prices from "components/Prices";
import axiosInstance from "api/AxiosInstance";
import CurrencyFormat from "components/CurrencyFormatter";
import { config } from "utils/Constance";
import ImageNotFound from "images/NoImageAvailable.png";
import SkeletonCart from "./SkeletonCart";
import { Dialog, Transition } from "@headlessui/react";
import Backdrop from "../../shared/Confirmdialogbox/Backdrop";
import DialogPanel from "../../shared/Confirmdialogbox/DialogPanel";
import Divider from "components/Divider";
import { getCartData } from "../../store/slices/cartSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getCartListData } from "../../store/slices/cartDetailListSlice";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import HeadingText1 from "components/HeadingText/HeadingText1";
import { getCartIndexListData } from "../../store/slices/cartIndexListSlice";
import ButtonCSCLed from "shared/Button/ButtonCSCLed";
import AlertMessage from "./AlertMessage";
import { isOutOfStock, ValidateQtyCount ,ValidateTotalInventoryQtyCount,isOutOfStockInventoryTotalDTO} from "utils/configration";
import SearchProductListbox from "./SearchProductListbox";
import { debounce } from "lodash";
import { object, string } from "yup";

export interface IndexProps {
  className?: string;
}

export interface InventoryItem {
  inventoryId: number;
  productNumber: string;
}

interface ValidateProductQty {
  isClearanceValidate: boolean;
  isOutOfStockValidate: boolean;
}

const Index: FC<IndexProps> = ({ className = "" }) => {
  let price: any;
  price = 0.0;

  let totalCartPrice: any;
  totalCartPrice = 0.0;

  let Discount: any;
  Discount = 0.0;

  const navigate = useNavigate();

  const defaultQuantityValidation: ValidateProductQty = {
    isClearanceValidate: false,
    isOutOfStockValidate: false,
  };

  const [shoppingCartDetails, setShoppingCartDetails] = useState<any>([]);
  const [websiteDiscount, setWebsiteDiscount] = useState<boolean>();
  //const [numberValue, setNumberValue] = useState(0);
  // const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [inventoriesList, setinventoriesList] = useState<InventoryItem[]>([]);
  const [validateProductQty, setValidateProductQty] =
    useState<ValidateProductQty>(defaultQuantityValidation);
  const initialFetch = useRef(true);

  const handleOpen = () => {
    setIsOpen(true);
    setShowModal(false);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleProcedToConfirm = () => {
    const validateClearanceCount = checkClearanceQty();

    /** Validate user to stop if clearance qty is greater*/
    if (validateClearanceCount > 0) {
      return;
    }
    navigate(`/cart/checkout-order`);
  };

  const checkClearanceQty = (): number => {
    const outofStockCount = ValidateTotalInventoryQtyCount(cartIndexList, true);
    setValidateProductQty((prevState) => ({
      ...prevState,
      isClearanceValidate: outofStockCount > 0,
    }));
    return outofStockCount;
  };

  const checkOrderQty = (): number => {
    const outofStockCount = ValidateQtyCount(cartIndexList, false);
    setValidateProductQty((prevState) => ({
      ...prevState,
      isOutOfStockValidate: outofStockCount > 0,
    }));
    return outofStockCount;
  };

  const fetchData = async () => {
    try {
      // After successfully fetching the cart details, proceed to the second API call
      const secondApiResponse = await axiosInstance.get(
        `/Cart/GetWebsiteDiscount`
      );
      setWebsiteDiscount(secondApiResponse.data);

      const inventoryApiResponse = await axiosInstance.get(
        `/products/getInventoriesList`
      );
      setinventoriesList(inventoryApiResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const dispatch = useAppDispatch();
  const cartIndexList = useAppSelector(
    (state) => state.cartIndexList.CartIndexDetailList
  );
  // const cartCount = useAppSelector((state) => state.cart.count);
  // const cartList = useAppSelector((state) => state.cartList.CartDetailList);

  useEffect(() => {
    if (initialFetch.current) {
      fetchData();
      checkClearanceQty();
      checkOrderQty();
      initialFetch.current = false;
    }
  }, []);

  // Ref to track if the dispatch has already happened
  // const hasDispatchedCartCount = useRef(false);
  // const hasDispatchedCartList = useRef(false);
  const hasDispatchedCartIndexList = useRef(false);

  // Do not remove this code.
  // useEffect(() => {
  //   if (!hasDispatchedCartCount.current && (!cartCount || cartCount === 0)) {
  //    // console.log("====>Entered here on Index cart count");
  //     dispatch(getCartData());
  //     hasDispatchedCartCount.current = true; // Mark as dispatched
  //   }
  // }, [dispatch, cartCount]);

  // useEffect(() => {
  //   // Reset the ref if cartCount is reset, allowing a new dispatch if needed
  //   if (cartCount > 0) {
  //     hasDispatchedCartCount.current = false;
  //   }
  // }, [cartCount]);

  // // Dispatch for cartList
  // useEffect(() => {
  //   if (!hasDispatchedCartList.current && (!cartList || cartList.length === 0)) {
  //    // console.log("====>Entered here on Cart List");
  //     dispatch(getCartListData());
  //     hasDispatchedCartList.current = true;
  //   }

  //   // Optionally reset if cartList gets populated
  //   if (cartList && cartList.length > 0) {
  //     hasDispatchedCartList.current = false;
  //   }
  // }, [dispatch, cartList]);

  // Dispatch for cartIndexList
  useEffect(() => {
    if (
      !hasDispatchedCartIndexList.current &&
      (!cartIndexList || cartIndexList.length === 0)
    ) {
      //console.log("====>Entered here on Cart Index List");
      dispatch(getCartIndexListData());
      hasDispatchedCartIndexList.current = true;
    }

    // Optionally reset if cartIndexList gets populated
    if (cartIndexList && cartIndexList.length > 0) {
      hasDispatchedCartIndexList.current = false;
    }
    // console.log("dispatch & cart list");
  }, [dispatch, cartIndexList]);

  useEffect(() => {
    setShoppingCartDetails(cartIndexList);
    checkClearanceQty();
    checkOrderQty();
  }, [cartIndexList]);

  const debouncedHandleChange = debounce(async (Id: any, newValue: any) => {
    try {
      // Handle the response from the API call
      await axiosInstance.post(`/Cart?CartItemid=${Id}&qtyValue=${newValue}`);
      fetchData();
      dispatch(getCartListData());
      dispatch(getCartIndexListData());
    } catch (error) {
      // Handle any errors that occurred during the API call
      console.error("Error saving value:", error);
    }
  }, 300); // Adjust the debounce time as needed (e.g., 300ms)

  const handleNumberChange = (event: any, Id: any) => {
    const newValue = event;
    debouncedHandleChange(Id, newValue);
  };

  // const handleNumberChange = async (event: any, Id: any) => {
  //   // Extract the new value from the event
  //   const newValue = event;

  //   // Update the state with the new value
  //   setNumberValue(newValue);
  //   try {
  //     // Make an API call to save the new value
  //     const response = await axiosInstance.post(
  //       `/Cart?CartItemid=${Id}&qtyValue=${newValue}`
  //     );
  //     // Handle the response from the API call
  //     fetchData();
  //     dispatch(getCartListData());
  //     dispatch(getCartIndexListData());
  //   } catch (error) {
  //     // Handle any errors that occurred during the API call
  //     console.error("Error saving value:", error);
  //   }
  // };

  const showDeleteConfirmation = (id: any) => {
    setSelectedItem(id);
    setShowConfirmation(true);
    handleOpen();
  };

  const handleDelete = () => {
    // Perform delete action
    setShowConfirmation(false);
    callDeleteAPI(selectedItem);
  };

  const callDeleteAPI = async (itemId: any) => {
    try {
      const id = itemId;
      await axiosInstance.get(`/Cart/DeleteCartItem?id=${id}`);
      // Remove the deleted item from the local state
      fetchData();
      dispatch(getCartData());
      dispatch(getCartListData());
      dispatch(getCartIndexListData());
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const warningAlert = (title: string, htmlbody: string) => {
    return (
      <div
        className={`bg-blue-50 text-csccolor px-4 py-3 rounded relative mt-4`}
        role="alert"
      >
        <strong className="font-bold">{title}</strong>
        <span
          className={`block sm:inline`}
          dangerouslySetInnerHTML={{ __html: htmlbody }}
        ></span>
      </div>
    );
  };

  const ReminderAccessories = ({ invArr = [] }) => {
    return (
      <div className="mt-2 space-y-1 text-sm text-slate-600 dark:text-slate-300 halloween:text-black">
        <span>Do you have what you need? This product requires one or more of the following:</span>
        <div className="flex flex-wrap gap-1">
          {invArr.map((value, index, array) => (
            <span key={index}>
              <Link
                to={`/product-details/${encodeURIComponent(value).toUpperCase()}`}
                className=""
              >
                {value}
              </Link>
              {index < array.length - 1 && <span>,&nbsp;</span>}
            </span>
          ))}
        </div>
      </div>
    );
};


  return (
    <div className={`nc-YourCartPage ${className}`} data-nc-id="YourCartPage">
      <div>
        <div>
          <Helmet>
            <title>Your Cart | CSC LED</title>
          </Helmet>
          {/* <CommonLayout>             */}
          <main className="container py-16 lg:pb-28 lg:pt-20 ">
            <div className="mb-12 sm:mb-16">
              <HeadingText1 className="">Your Cart</HeadingText1>
              <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400 halloween:text-black">
                <Link to={"/#"} className="">
                  Homepage
                </Link>
                {/* <span className="text-xs mx-1 sm:mx-1.5">/</span>
                <Link to={"/#"} className="">
                  Product Details
                </Link> */}
                <span className="text-xs mx-1 sm:mx-1.5">/</span>
                <span className="underline">Your Cart</span>
              </div>
            </div>
            <Divider className="my-10 xl:my-12" />
            <div className="flex flex-col lg:flex-row">
              {/* {loading ? (
                <SkeletonCart />
              ) : ( */}
              <div className="w-full lg:w-[60%] xl:w-[55%] divide-y divide-slate-200 dark:divide-slate-700 ">
                {/* repeat order this  */}
                {shoppingCartDetails != null &&
                shoppingCartDetails.length !== 0 ? (
                  shoppingCartDetails.map((item: any, index: any) => (
                    <div className="relative flex py-8 sm:py-10 xl:py-12 first:pt-0 last:pb-0">
                      <div className="cursor-pointer relative p-4 h-36 w-24 sm:w-32 flex-shrink-0 overflow-hidden rounded-xl border border-gray-200 halloween:border-white halloween:bg-white">
                        {item.inventoryImagesLowRes != null &&
                        item.inventoryImagesLowRes.length !== 0 ? (
                          <>
                            <Link
                              to={`/product-details/${encodeURIComponent(
                                item.invDTO.productNumber
                              ).toUpperCase()}`}
                              className="absolute inset-0"
                            >
                              <img
                                src={
                                  config.url.ERP_Domain +
                                  item.inventoryImagesLowRes[0].imageUrl
                                }
                                alt={`productImage`}
                                className="h-full w-full object-contain object-center"
                              />
                            </Link>
                          </>
                        ) : (
                          <Link
                            to={`/product-details/${encodeURIComponent(
                              item.invDTO.productNumber
                            ).toUpperCase()}`}
                            className="absolute inset-0"
                          >
                            <img
                              src={ImageNotFound}
                              alt={`productImageNotfound`}
                              className="h-full w-full object-contain object-center"
                            />
                          </Link>
                        )}
                      </div>

                      <div className="ml-3 sm:ml-6 pb-2 justify-between flex flex-1 flex-col">
                        <div>
                          <div className="flex justify-between">
                            <div className="flex-[1.3]">
                              <h3 className="text-base font-semibold">
                                <Link
                                  to={`/product-details/${encodeURIComponent(
                                    item.invDTO.productNumber
                                  ).toUpperCase()}`}
                                >
                                  {item.invDTO != null
                                    ? item.invDTO.productNumber
                                    : ""}
                                </Link>
                              </h3>
                              <div className="mt-1.5 sm:mt-2.5 flex text-sm text-slate-600 dark:text-slate-300 halloween:text-black">
                                <div className="flex items-center space-x-1.5">
                                  <span>Unit Price</span>
                                </div>
                                <span className="mx-4 border-l border-slate-300 dark:border-slate-700 "></span>
                                <div className="flex items-center space-x-1.5">
                                  <span>
                                    <CurrencyFormat
                                      value={item.price.toFixed(2)}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="sm:block hidden text-center relative">
                              <NcInputNumber
                                defaultValue={item.qty}
                                onChange={(e: any) =>
                                  handleNumberChange(e, item.id)
                                }
                                className="relative z-10"
                              />
                            </div>

                            <div
                              className="flex-1 sm:flex justify-end"
                              style={{ display: "flex" }}
                            >
                              <Prices
                                price={item.totalPrice}
                                className="mt-0.5"
                              />
                            </div>
                          </div>
                          {item.reminderAccessoriesArr &&
                            item.reminderAccessoriesArr.length > 0 && (
                              <ReminderAccessories
                                invArr={item.reminderAccessoriesArr}
                              />
                            )}
                          {/**Validate stock quantity */}                       
                          {item.invDTO.clearance ? (
                            <div>
                        {isOutOfStockInventoryTotalDTO(
                            item.qty,
                            item.inventoryQTYDTO.totalAvailableForSale,
                          ) && (
                            <div
                              className={`${
                                item.invDTO.clearance
                                  ? "text-red-500"
                                  : "text-csccolor"
                              } flex mt-1.5 sm:mt-2.5 space-x-1 items-center`}
                            >
                              <ExclamationTriangleIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                              <span className={`font-semibold`}>
                                Out of stock{" "}
                                {`${
                                  item.invDTO.clearance
                                    ? "and on clearance"
                                    : ""
                                }`}
                              </span>
                            </div>
                          )}
                              </div>
                            
                          ) : ( <div>
                            {isOutOfStock(
                            item.qty,
                            item.invDTO.abQty,
                            item.invDTO.bcQty,
                            item.invDTO.onQTY
                          ) && (
                            <div
                              className={`${
                                item.invDTO.clearance
                                  ? "text-red-500"
                                  : "text-csccolor"
                              } flex mt-1.5 sm:mt-2.5 space-x-1 items-center`}
                            >
                              <ExclamationTriangleIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                              <span className={`font-semibold`}>
                                Out of stock{" "}
                                {`${
                                  item.invDTO.clearance
                                    ? "and on clearance"
                                    : ""
                                }`}
                              </span>
                            </div>
                          )}
                              </div>
                        )}

                          
                        </div>
                        {/* calculate totalPrice of order */}
                        <span hidden>
                          {(totalCartPrice = totalCartPrice + item.totalPrice)}
                        </span>
                        <div className="flex justify-between sm:justify-end">
                          <div className="sm:hidden block text-center relative">
                            <NcInputNumber
                              defaultValue={item.qty}
                              onChange={(e: any) =>
                                handleNumberChange(e, item.id)
                              }
                              className="relative z-10"
                            />
                          </div>
                          <div className="flex items-end justify-end text-sm">
                            <a
                              href="javascript:void(0);"
                              onClick={() => showDeleteConfirmation(item.id)}
                              className="relative z-10 flex items-center font-medium text-primary-600 hover:text-primary-500 text-sm "
                            >
                              <span className="text-csccolor">Remove</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="halloween:text-black">
                    {" "}
                    Sadly, your cart is empty. Try the quick search below to
                    remedy the situation.
                  </p>
                )}
                {/* end order */}
              </div>
              {/* )} */}
              <div className="border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 halloween:border-white my-10 lg:my-0 lg:mx-10 xl:mx-16 2xl:mx-20 flex-shrink-0"></div>
              <div className="flex-1">
                <div className="sticky top-28">
                  <h3 className="text-lg font-semibold ">Order Summary</h3>
                  <div className="mt-7 text-sm text-slate-500 dark:text-slate-400 halloween:text-black">
                    {websiteDiscount === true ? (
                      <div className="flex justify-between py-4 border-b border-slate-200 dark:border-slate-700/80 halloween:border-white">
                        <span hidden>{(Discount = totalCartPrice * 0.02)}</span>
                        <span className="text-csccolor-green font-semibold capitalize">
                          2% Online Order Discount
                        </span>
                        <span className="text-csccolor-green font-semibold dark:text-slate-200">
                          -<CurrencyFormat value={Discount.toFixed(2)} />
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                      <span>Subtotal</span>
                      <span hidden>
                        {(totalCartPrice = totalCartPrice - Discount)}
                      </span>
                      <span>
                        {" "}
                        <CurrencyFormat value={totalCartPrice.toFixed(2)} />
                      </span>
                    </div>
                    {validateProductQty.isClearanceValidate && (
                      <div>
                        <AlertMessage
                          className="mt-4"
                          message={`An item in your cart is on clearance and we do not have enough in stock.
                              <br /><br />To proceed with this order, you either need to remove this item or reduce the quantity in your cart.`}
                        />
                      </div>
                    )}
                    {validateProductQty.isOutOfStockValidate && (
                      <div>
                        {warningAlert(
                          "Just a quick FYI! ",
                          `An item in your cart is out of stock and will be placed on back order.
                              <br /><br />Please contact <a href="mailto:orders@csc-led.com"><b>orders@csc-led.com</b></a> if you have any questions.`
                        )}
                      </div>
                    )}
                  </div>
                  {shoppingCartDetails != null &&
                  shoppingCartDetails.length !== 0 ? (
                    <ButtonCSCLed
                      //href="/Cart/checkout-order"
                      className="mt-8 w-full disabled:bg-gray-400"
                      disabled={validateProductQty.isClearanceValidate}
                      onClick={handleProcedToConfirm}
                    >
                      Proceed to Order Confirmations
                    </ButtonCSCLed>
                  ) : (
                    <ButtonCSCLed
                      type="submit"
                      disabled={true}
                      className="mt-8 w-full"
                    >
                      Proceed to Order Confirmation
                    </ButtonCSCLed>
                  )}

                  <div className="mt-5 text-sm text-csccolor flex items-center justify-center">
                    <p>
                      or{" "}
                      <Link className="font-medium" to={"/product-type"}>
                        continue perusing
                      </Link>
                      <span aria-hidden="true"> &rarr;</span>
                    </p>
                  </div>
                  {showConfirmation && (
                    <Transition appear show={isOpen} as={Fragment}>
                      <Dialog
                        as="div"
                        className="relative z-10"
                        onClose={handleClose}
                      >
                        <Backdrop isOpen={isOpen} />
                        <div className="fixed inset-0 overflow-y-auto">
                          <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <DialogPanel
                              title={"Are you sure?"}
                              message={
                                "Are you sure you want to remove this item from your cart?"
                              }
                              onConfirm={handleDelete}
                              handleClose={handleClose}
                            />
                          </div>
                        </div>
                      </Dialog>
                    </Transition>
                  )}
                </div>
              </div>
            </div>

            <Divider className="my-10 xl:my-12" />
            {/**Know what you need search list*/}
            <SearchProductListbox inventoriesList={inventoriesList} />
          </main>
        </div>
      </div>
    </div>
  );
};

export default Index;
